import React from "react";
import { Col, Container, Row } from "reactstrap";
import GalleryCarousel from "./Carousel/GalleryCarousel";
import gallery_pic1 from "../assets/img/nb.jpg";
import gallery_pic2 from "../assets/img/nt.jpeg";
import gallery_pic3 from "../assets/img/pb.png";
import gallery_pic4 from "../assets/img/sl.png";
import gallery_pic5 from "../assets/img/ll.png";
import gallery_pic6 from "../assets/img/nb.jpg";
import SectionTitle from "./SectionTitle";

function DemoGallery() {
    const teamData = [
        {
            image: gallery_pic1,
            text:"Neha Bose, Managing Director" ,
        },
	 {
            image: gallery_pic2,
            text: "Nishant Thakur, Founder & Chief Innovation Officer (CIO)",
        },
        {
            image: gallery_pic3,
            text: "Priya Bose, Director of Market Expansion",
        },
        {
            image: gallery_pic4,
            text: "Song Hua Lee, Founding  and Scientific Board Member",
        },
	        {
            image: gallery_pic5,
            text: "Luc Langlet, Board Member",
        },
    ];
    return (
        <>
            <Container id="demo">
                <Row className="justify-content-center py-5">
                    <Col lg="5=7" className="pt-5">
                        <div class="d-flex flex-column align-items-center">
                            <SectionTitle
                                subTitle="TEAM"
                                title="Meet our super friendly Team of DeepMedAI"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="demo__carousel my-4">
                <GalleryCarousel teamData={teamData} />
            </div>
        </>
    );
}

export default DemoGallery;
