import scRNASeqBanner from '../assets/img/HomeBg_scRNA.jpg';
import aiCellTyping from '../assets/img/HomeBg_spat.jpg';
import PersonMed from '../assets/img/HomeBg_person.jpg';
import FUSION from '../assets/img/DM-IA.gif';
import Transcriptomics from '../assets/img/Transcriptomics.jpg';
import Genomics from '../assets/img/Genomics.jpg';
import Proteomics from '../assets/img/Proteomics.jpg';
import Fusion from '../assets/img/Fusion.jpg';
import IPP from '../assets/img/IntegratedPatientProfiling.jpeg';
import Pharmacogenomic from '../assets/img/Pharmacogenomic.jpeg';
import TTD from '../assets/img/TTD.jpeg';
import RadarPlot from '../assets/img/RadarPlot.jpeg';
import UnifiedOmicsIntegration from '../assets/img/UnifiedOmicsIntegration.gif';
import SpatialTranscriptomics  from '../assets/img/SpatialTranscriptomics.gif';
const mockProductDatabase = {
  "sc-omics": {
    id: "sc-omics",
    title: "Single-cell ~omics",
    imgSrc: scRNASeqBanner,
    description: "Our single-cell omics analysis platform offers a comprehensive suite of services, including advanced genomics, transcriptomics, and proteomics, to provide an in-depth understanding of cellular functions at an unprecedented resolution. Leveraging cutting-edge technologies such as CyTOF, flow cytometry, and AI-enhanced analytical tools, we deliver high-dimensional data and actionable insights tailored to your research needs. Our integrated, multi-omics analysis, powered by our proprietary internal database, enables holistic exploration of complex biological processes, biomarker discovery, and therapeutic target validation. From custom pipelines to user-friendly data visualization, we provide an end-to-end, scalable solution for impactful scientific breakthroughs.",
    keyFeatures: [
      "High-resolution cell type identification",
      "Advanced trajectory inference",
      "Integrated differential expression analysis",
      "Comprehensive cell atlas generation"
    ],
    cardsContent: [
      {
        title: "Comprehensive Single-Cell Genomics",
        description: "Beyond Basic Genomic Sequencing: We provide in-depth single-cell genomic analysis, offering insights into genetic heterogeneity, mutations, and structural variations at an unprecedented level of resolution. Utilize our AI-enhanced predictive models to uncover potential genetic outcomes and actionable targets for drug discovery and development",
        image: Genomics
      },
      {
        title: "Advanced Single-Cell Transcriptomics",
        description: "High-Resolution Expression Profiling: Decode the complexities of gene expression patterns at a single-cell level with unparalleled sensitivity and specificity. Dynamic Gene Regulation: Track dynamic changes in cellular expression profiles, enabling a better understanding of cellular states, differentiation pathways, and disease mechanisms",
        image: Transcriptomics
      },
      {
        title: "Cutting-Edge Single-Cell Proteomics",
        description: "Proteomic Profiling at Single-Cell Resolution: Utilize advanced technologies such as CyTOF (Cytometry by Time-of-Flight), flow cytometry, and mass spectrometry to explore protein expression, modifications, and interactions at the single-cell level.Integrated Proteomics Tools: Combine CyTOF, flow cytometry, and mass spectrometry data with genomics and transcriptomics insights for a comprehensive, multi-layered understanding of biological processes.",
        image: Proteomics
      },
      {
        title: "AI-Powered Cell Atlas",
        description: "Our proprietary AI algorithms create comprehensive, dynamic cell atlases, offering unparalleled insights into cellular landscapes across tissues and conditions.Harnessing advanced AI algorithms, our AI-Powered Cell Atlas constructs dynamic, detailed maps of cellular landscapes across diverse tissues and conditions. It reveals hidden patterns and interactions, offering profound insights into cellular behaviors, disease mechanisms, and potential therapeutic targets with unparalleled precision",
        image: Fusion
      }
    ]
  },
  "Spatial-omics": {
    id: "Spatial-omics",
    title: "Spatial Omics Analysis Platform",
    imgSrc: aiCellTyping,
    description: "Revolutionize your understanding of tissue architecture and cellular interactions with our advanced spatial omics platform. Visualize and analyze gene expression patterns in their native spatial context.",
    keyFeatures: [
      "High-resolution spatial transcriptomics/proteomics: Capture detailed gene expression patterns with unmatched spatial precision.",
      "3D tissue reconstruction: Convert 2D spatial data into interactive 3D models for a new level of cellular and tissue visualization",
      "Spatial domain identification: Automatically delineate and analyze distinct spatial domains within tissues to reveal functional and regional gene expression patterns.",
      "Cell-cell interaction mapping: Map and visualize cell-cell communication networks based on spatial proximity and gene expression, uncovering complex tissue-specific interactions."
    ],
    cardsContent: [
      {
        title: "Spatial Transcriptomics",
        description: "Analyze gene expression within the precise spatial context of tissues using techniques such as CosMX, Visium, Slide-seq,etc. This integration provides a comprehensive view of cellular function across different tissue regions.",
        image: SpatialTranscriptomics
      },
      {
        title: "Spatial proteomics",
        description: "Explore protein expression and localization within tissues using techniques like CODEX,IBEX,CyCIF,MIBI,and etc. Integrate data from these advanced platforms to gain insights into protein distribution and interactions in their native spatial environments.",
        image: SpatialTranscriptomics
      },
      {
        title: "Spatial Domain Analysis",
        description: "Automatically identify and characterize distinct spatial domains within tissues, revealing functional organization and region-specific gene expression signatures.",
        image: SpatialTranscriptomics
      },
      {
        title: "Spatially-Resolved Cell-Cell Communication",
        description: "Infer and visualize cell-cell communication networks based on spatial proximity and gene expression profiles, uncovering tissue-specific cellular ecosystems.",
        image: SpatialTranscriptomics
      }
    ]
  },
  "Person-Med": {
    id: "Person-Med",
    title: "Personalized Medicine Omics Suite",
    imgSrc: PersonMed,
    description: "Harness the power of multi-omics data to drive precision medicine forward. Our personalized medicine platform integrates diverse biological data to inform tailored therapeutic strategies and clinical decision-making.",
    keyFeatures: [
      "Patient-specific multi-omics integration",
      "AI-driven treatment response prediction",
      "Pharmacogenomic profiling",
      "Longitudinal health trajectory modeling"
    ],
    cardsContent: [
      {
        title: "Integrated Patient Profiling (IP)",
        description: "Create comprehensive patient profiles by integrating genomics, transcriptomics, proteomics, and metabolomics data with clinical information for holistic health assessment.",
        image: IPP
      },
      {
        title: "Targeted Therapeutic Development(TTD)",
        description: "Leverage advanced analytics and machine learning to design targeted therapeutic strategies based on comprehensive multi-omics profiles. Our approach enables pharmaceutical companies to identify optimal treatment pathways, enhancing drug development processes and facilitating the creation of more effective, patient-centered therapies. This empowers stakeholders to align drug candidates with specific biological markers, improving overall treatment outcomes in diverse populations.",
        image: TTD
      },
      {
        title: "Pharmacogenomic Analysis (PA)",
        description: "Identify genetic variants influencing drug response and metabolism to guide medication selection and dosing for improved efficacy and reduced adverse effects.",
        image: Pharmacogenomic
      },
      {
        title: "Disease Risk Assessment (DRA)",
        description: "Calculate personalized disease risk scores and provide actionable preventive strategies based on integrated omics data and lifestyle factors.",
        image: RadarPlot
      }
    ]
  },
  "Person-Med": {
    id: "Person-Med",
    title: "Personalized Medicine Omics Suite",
    imgSrc: PersonMed,
    description: "Harness the power of multi-omics data to drive precision medicine forward. Our personalized medicine platform integrates diverse biological data to inform tailored therapeutic strategies and clinical decision-making.",
    keyFeatures: [
      "Patient-specific multi-omics integration",
      "AI-driven treatment response prediction",
      "Pharmacogenomic profiling",
      "Longitudinal health trajectory modeling"
    ],
    cardsContent: [
      {
        title: "Integrated Patient Profiling (IP)",
        description: "Create comprehensive patient profiles by integrating genomics, transcriptomics, proteomics, and metabolomics data with clinical information for holistic health assessment.",
        image: IPP
      },
      {
        title: "Targeted Therapeutic Development(TTD)",
        description: "Leverage advanced analytics and machine learning to design targeted therapeutic strategies based on comprehensive multi-omics profiles. Our approach enables pharmaceutical companies to identify optimal treatment pathways, enhancing drug development processes and facilitating the creation of more effective, patient-centered therapies. This empowers stakeholders to align drug candidates with specific biological markers, improving overall treatment outcomes in diverse populations.",
        image: TTD
      },
      {
        title: "Pharmacogenomic Analysis (PA)",
        description: "Identify genetic variants influencing drug response and metabolism to guide medication selection and dosing for improved efficacy and reduced adverse effects.",
        image: Pharmacogenomic
      },
      {
        title: "Disease Risk Assessment (DRA)",
        description: "Calculate personalized disease risk scores and provide actionable preventive strategies based on integrated omics data and lifestyle factors.",
        image: RadarPlot
      }
    ]
  },
  "MO-DI1": {
    id: "MO-DI",
    title: "Omics Fusion: Integrated Multi-Omics Platform",
    imgSrc: FUSION,
    description: "Break down data silos with our Omics Fusion platform. Seamlessly integrate and analyze diverse omics data types to uncover complex biological insights and drive innovative research.",
    keyFeatures: [
      "Cross-platform omics data integration",
      "Multi-modal data visualization",
      "AI-powered cross-omics pattern recognition",
      "Customizable multi-omics workflows"
    ],
    cardsContent: [
      {
        title: "Unified Omics Integration",
        description: "Effortlessly combine data from genomics, transcriptomics, proteomics, metabolomics, and more within a single, powerful analytical framework.",
        image: UnifiedOmicsIntegration
      },
      {
        title: "Cross-Omics Visualization",
        description: "Explore relationships between different omics layers with interactive, integrated visualizations that reveal hidden patterns and correlations across data types.",
        image: UnifiedOmicsIntegration
      },
      {
        title: "AI-Driven Insight Discovery",
        description: "Leverage advanced machine learning algorithms to automatically identify significant patterns and relationships across multi-omics datasets.",
        image: UnifiedOmicsIntegration
      },
      {
        title: "Customizable Analysis Pipelines",
        description: "Design and implement tailored multi-omics analysis workflows with our intuitive drag-and-drop interface, backed by powerful computational resources.",
        image: UnifiedOmicsIntegration
      }
    ]
  }
};

export default mockProductDatabase;
