import React from "react";
import Header from "../components/Header/Header";
import HomeBg from "../assets/img/HomeBg_person.jpg";
import Image1 from "../assets/img/PersonMed1.png";
import Image2 from "../assets/img/PersonMed2.png";
import Image3 from "../assets/img/Hist4.png";
import Image4 from "../assets/img/Hist11.png";
import "../assets/css/BlogPost.css"; // Import the CSS file for additional styling

function BlogPost() {
  return (
    <>
      <Header url={HomeBg} title={"Personalized medicine"} />
      <text> Please check back later; we are currently updating the content of this page..</text>
    </>
  );
}

export default BlogPost;
