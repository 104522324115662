import React from "react";
import { Col, Container, Nav, NavItem, Row, NavLink } from "reactstrap";
import logo from "../../assets/img/logoFooter.png";
import { Link } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

let links = [
    { to: "home", text: "HOME" },
    { to: "about-us", text: "ABOUT US" },
    { to: "products", text: "SERVICES" },
    { to: "teams", text: "TEAM" },
    { to: "demo", text: "GALLERY" },
    { href: "mailto:contact@deepmedai.com", text: "CONTACT" },
    { href: "/login", text: "LOGIN" },
];

const Footer = () => {
    const location = useLocation();

    return (
        <div className="footer-main py-5 px-2 px-md-5 text-dark">
            <Container>
                <Row>
                    <Col md={4}>
                        <div className="footer-main__left">
                            <img className="mb-3 footer-logo" src={logo} alt="DEEPMEDAI" />
                            <div className="footer-main__left-text">
                                <p className="fs--18 text-justify">
                                    Small changes big impacts in Healthcare. Inspired by chaos theory, 
                                    we are transforming personalized medicine through cutting-edge AI 
                                    solutions. Unlocking hidden patterns to drive groundbreaking advancements.
                                </p>
                            </div>
                            <p className="footer-main__left-text mb-0">
                                © 2024 - deepmedai.com
                            </p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="footer-main__center mt-4 mt-md-0">
                            <p className="footer-main__center-address">
                                1A Place du 4 Septembre, Marseille-13007, France 
                            </p>
<div className="footer-main__center-social">
    <a href="https://twitter.com/DeepMedAI" className="footer-social-link">Twitter</a>
    <a href="https://www.facebook.com/profile.php?id=100093333761107" className="footer-social-link">Facebook</a>
    <a href="https://www.linkedin.com/company/deepmedai/" className="footer-social-link">Linkedin</a>
    <a href="mailto:nishant.thakur@deepmedai.com" className="footer-main__center-mail">Email</a>
</div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="footer-main__right mt-4 mt-md-0">
                            <h3>Site map</h3>
                            <Nav className="flex-column" navbar>
                                {links.map((link) => (
                                    <NavItem key={link.text}>
                                        {link.to && location.pathname === "/" ? (
                                            <NavLink
                                                tag={Link}
                                                to={link.to}
                                                activeClass="active"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                className="cursor-pointer"
                                            >
                                                {link.text}
                                            </NavLink>
                                        ) : link.to ? (
                                            <HashLink
                                                to={`/#${link.to}`}
                                                className="nav-link"
                                            >
                                                {link.text}
                                            </HashLink>
                                        ) : (
                                            <NavLink href={link.href}>
                                                {link.text}
                                            </NavLink>
                                        )}
                                    </NavItem>
                                ))}
                            </Nav>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;